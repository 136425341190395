const adminRoutes = {
  dashboard: {
    order: 1,
    route: {name: 'AdminDashboard'},
  },
  client: {
    order: 2,
    route: {name: 'AdminClient'},
  },
  creative: {
    order: 3,
    route: {name: 'AdminCreative'},
  },
  target: {
    order: 4,
    route: {name: 'AdminTarget'},
  },
  campaign: {
    order: 5,
    route: {name: 'AdminCampaign'},
  },
  statistic: {
    order: 6,
    route: {name: 'AdminStatistic'},
  },
  segment: {
    order: 7,
    route: {name: 'AdminSegment'},
  },
  rule: {
    order: 8,
    route: {name: 'AdminRule'},
  },
  tag: {
    order: 9,
    route: {name: 'AdminTag'},
  },
  finance: {
    order: 10,
    route: {name: 'AdminFinances'},
  },
  security: {
    order: 11,
    route: {name: 'AdminSecurity'},
  },
  integration: {
    order: 12,
    route: {name: 'AdminIntegration'},
  },
  doc: {
    order: 13,
  },
}

export default adminRoutes
