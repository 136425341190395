import i18n from '@/i18n/i18n'

const customErrors = async (err, dispatch, view = 'SHOW_TOAST_ERROR', message) => {
  const {response: {data = {}} = {}} = err

  const messageItems = {
    BAD_CREDENTIALS: 'toast.authCredentials',
    EMAIL_IN_USE: 'toast.emailUse',
    PHONE_IN_USE: 'toast.phoneUse',
    CONTRACT_NUMBER_IN_USE: 'toast.contractNumberUse',
    CREATE_TARGET_ERROR: 'toast.createTargetError',
    NO_MODERATED_CREATIVES: 'toast.noModeratedCreatives',
    FILE_NOT_FOUND: 'toast.fileNotFound',
    USER_CONTRACT_NOT_FOUND: 'toast.fileNotFound',
    EXCHANGE_RATE_SAME_CURRENCY: 'toast.exchangeRateSame',
    EXCHANGE_RATE_EXIST: 'toast.exchangeRateExist',
    CAMPAIGN_END_DATE_PAST_ERROR: 'toast.campaignEndDatePast',
    VAT_EXIST: 'toast.financeVatExist',
    LOGIN_IN_USE: 'toast.loginInUse',
    PUBLIC_ZONE_INFO_NOT_FOUND: 'toast.infoNotFound',
    NOT_POSSIBLE_UPDATE_VAST_DAAST_CREATIVE: 'toast.notPossibleUpdateCreative',
    PASSWORDS_DO_NOT_MATCH: 'toast.passwordsDoNotMatch',
    CURRENT_PASSWORD_IS_WRONG: 'toast.currentPasswordIsWrong',
    RESET_PASSWORD_TOKEN_EXPIRED_ERROR: 'toast.expiredPasswordToken',
    SUCCESS: 'toast.success',
    UNEXPECTED: 'toast.unexpected',
    RULE_IN_USE: 'toast.ruleInUse',
    IS_NOT_HUMAN: 'toast.isNotHuman',
    VK_ORD_ERROR: 'toast.vkOrdError',
    BANK_IS_BLANK: 'toast.bankIsBlank',
    TARGET_IN_USE: 'toast.targetInUse',
    STORAGE_ERROR: 'toast.storageError',
    USER_NOT_FOUND: 'toast.userNotFound',
    CREATIVE_IN_USE: 'toast.creativeInUse',
    FILE_READ_ERROR: 'toast.fileReadError',
    COUNTRY_IS_BLANK: 'toast.countryIsBlank',
    PDF_RENDER_ERROR: 'toast.pdfRenderError',
    YANDEX_ORD_ERROR: 'toast.yandexOrdError',
    BANNER_SIZE_ERROR: 'toast.bannerSizeError',
    CREATE_RULE_ERROR: 'toast.createRuleError',
    NOT_HAS_PERMISSION: 'toast.notHasPermission',
    RESET_PASSWORD_ERROR: 'toast.resetPasswordError',
    USER_STATISTIC_ERROR: 'toast.userStatisticError',
    PARSE_TEXT_FILE_ERROR: 'toast.parseTextFileError',
    CREATE_CREATIVE_ERROR: 'toast.createCreativeError',
    UPDATE_CREATIVE_ERROR: 'toast.updateCreativeError',
    UPDATE_CAMPAIGN_ERROR: 'toast.updateCampaignError',
    CREATE_CAMPAIGN_ERROR: 'toast.createCampaignError',
    BANK_ACCOUNT_IS_BLANK: 'toast.bankAccountIsBlank',
    AGREEMENT_TYPE_IS_NULL: 'toast.agreementTypeIsNull',
    USER_INVOICE_NOT_FOUND: 'toast.userInvoiceNotFound',
    AGREEMENT_NOT_ACCEPTED: 'toast.agreementNotAccepted',
    AGREEMENT_DATE_IS_NULL: 'toast.agreementDateIsNull',
    USER_ACT_FILE_IS_EXISTS: 'toast.userActFileIsExists',
    USER_ACT_FILE_NOT_EXISTS: 'toast.userActFileNotExists',
    AGREEMENT_EFFECT_IS_NULL: 'toast.agreementEffectIsNull',
    PASSWORD_ALREADY_CHANGED: 'toast.passwordAlreadyChanged',
    USER_VAST_DAAST_EXCEPTION: 'toast.userVastDaastException',
    AGREEMENT_SUBJECT_IS_NULL: 'toast.agreementSubjectIsNull',
    AGREEMENT_NUMBER_IS_BLANK: 'toast.agreementNumberIsBlank',
    ORGANIZATION_TYPE_IS_NULL: 'toast.organizationTypeIsNull',
    USER_INVOICE_IS_PAID_ERROR: 'toast.userInvoiceIsPaidError',
    UNKNOWN_USER_COUNTRY_ERROR: 'toast.unknownUserCountryError',
    BIN_OR_SWIFT_CODE_IS_BLANK: 'toast.binOrSwiftCodeIsBlank',
    REGISTRATION_CODE_IS_BLANK: 'toast.registrationCodeIsBlank',
    ORGANIZATION_NAME_IS_BLANK: 'toast.organizationNameIsBlank',
    USER_PAYMENT_INFO_NOT_FOUND: 'toast.userPaymentInfoNotFound',
    ADD_USER_PAYMENT_INFO_ERROR: 'toast.addUserPaymentInfoError',
    USER_INVOICE_FILE_IS_EXISTS: 'toast.userInvoiceFileIsExists',
    VK_ORD_REGISTER_PERSON_ERROR: 'toast.vkOrdRegisterPersonError',
    USER_CONTRACT_FILE_IS_EXISTS: 'toast.userContractFileIsExists',
    USER_INVOICE_FILE_NOT_EXISTS: 'toast.userInvoiceFileNotExists',
    ORGANIZATION_ADDRESS_IS_BLANK: 'toast.organizationAddressIsBlank',
    USER_CONTRACT_IS_SIGNED_ERROR: 'toast.userContractIsSignedError',
    VK_ORD_REGISTER_CONTRACT_ERROR: 'toast.vkOrdRegisterContractError',
    CAMPAIGN_EXCHANGES_EMPTY_ERROR: 'toast.campaignExchangesEmptyError',
    MAX_COUNT_GROUP_PARAMS_EXCEEDED: 'toast.maxCountGroupParamsExceeded',
    MEDIATOR_AGREEMENT_TYPE_IS_NULL: 'toast.mediatorAgreementTypeIsNull',
    MEDIATOR_AGREEMENT_DATE_IS_NULL: 'toast.mediatorAgreementDateIsNull',
    NOT_SUPPORT_FILE_EXTENSION_ERROR: 'toast.notSupportFileExtensionError',
    USER_BALANCE_REPLENISHMENT_ERROR: 'toast.userBalanceReplenishmentError',
    STATE_REGISTRATION_CODE_IS_BLANK: 'toast.stateRegistrationCodeIsBlank',
    VK_ORD_REGISTER_MEDIA_FILE_ERROR: 'toast.vkOrdRegisterMediaFileError',
    HTML5_BANNER_ZIP_FILE_PARSE_ERROR: 'toast.html5BannerZipFileParseError',
    MEDIATOR_AGREEMENT_EFFECT_IS_NULL: 'toast.mediatorAgreementEffectIsNull',
    MEDIATOR_AGREEMENT_NUMBER_IS_NULL: 'toast.mediatorAgreementNumberIsNull',
    USER_ACT_WORK_PERFORMED_NOT_FOUND: 'toast.userActWorkPerformedNotFound',
    MEDIATOR_AGREEMENT_SUBJECT_IS_NULL: 'toast.mediatorAgreementSubjectIsNull',
    MEDIATOR_ORGANIZATION_TYPE_IS_NULL: 'toast.mediatorOrganizationTypeIsNull',
    MEDIATOR_ORGANIZATION_NAME_IS_NULL: 'toast.mediatorOrganizationNameIsNull',
    MEDIATOR_REGISTRATION_CODE_IS_NULL: 'toast.mediatorRegistrationCodeIsNull',
    CURRENT_USER_WRITE_OFF_BALANCE_ERROR: 'toast.currentUserWriteOffBalanceError',
    CURRENT_USER_ACT_WORK_PERFORMED_ERROR: 'toast.currentUserActWorkPerformedError',
    USER_CONTRACT_FILE_NOT_UPLOADED_ERROR: 'toast.userContractFileNotUploadedError',
    ECONOMIC_ACTIVITY_TYPE_NUMBER_IS_BLANK: 'toast.economicActivityTypeNumberIsBlank',
    YANDEX_ORD_REGISTER_ORGANISATION_ERROR: 'toast.yandexOrdRegisterOrganisationError',
    ADD_DAAST_VAST_MARKUP_PERMISSION_DENIED: 'toast.addDaastVastMarkupPermissionDenied',
  }

  const frontError = messageItems[data?.code]

  if (frontError) {
    dispatch(view, i18n.t(frontError), {root: true})
  } else {
    dispatch(view, i18n.t(message) || i18n.t('toast.error') || data.message, {root: true})
  }
}

export default customErrors
