import {actionItem, editItem, getItems} from '@/store/main'
import * as financeType from '@/helpers/variables/type'
import axios from '@/plugins/axios'
import i18n from '@/i18n/i18n'
import customErrors from '@/store/error'
import {currencySymbols} from '@/helpers/variables/currencies'
import {addFilter, createParams, isDuplicateFilter, updateFilter} from '@/helpers/filterHelper'

const TYPE_LOADING_ITEMS = 'ITEMS'
const TYPE_LOADING_ACTION = 'ACTION'

const DEFAULT_PARAMS = {
  page: 0,
  size: 20,
  sort: 'id,desc',
}

const state = () => ({
  items: [],
  item: null,
  balance: null,
  loading: false,
  actionLoading: false,
  dataPagination: {},
  params: {...DEFAULT_PARAMS},
  contract: null,
  paymentInfo: null,
  countryItems: [],
  isShowFilterBar: false,
  selectedAppFilter: {},
  selectedTableFilters: [],
})

const getters = {
  permission: (state, getters, rootState) => {
    return {
      readAll: rootState.auth?.permissions?.some((a) => a === 'FINANCES_READ_ALL'),
      balanceUpdate: rootState.auth?.permissions?.some((a) => a === 'FINANCES_CLIENT_BALANCE_UPDATE'),
      readAllVat: rootState.auth?.permissions?.some((a) => a === 'VAT_READ_ALL'),
      createVat: rootState.auth?.permissions?.some((a) => a === 'VAT_CREATE'),
      updateVat: rootState.auth?.permissions?.some((a) => a === 'VAT_UPDATE'),
    }
  },
}

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items.map((a) => {
      if (a.currency) {
        const currency = currencySymbols[a.currency] ?? a.currency
        return {
          ...a,
          amount: `${a.amount} ${currency}`,
        }
      } else {
        return {...a}
      }
    })
  },
  SET_PAGINATION(state, data) {
    state.dataPagination = {
      pageNumber: data.number + 1,
      totalPages: data.totalPages,
    }
  },
  SET_PARAMS(state, params) {
    state.params = {
      ...state.params,
      ...params,
    }
  },
  DELETE_PARAM(state, param) {
    delete state.params[param.name]
  },
  SET_PAGE_NUMBER(state, {page}) {
    state.params = {
      ...state.params,
      page: page - 1,
    }
  },
  SET_SORT_PARAMS(state, {sortNameParam, sortSetParam}) {
    const sortStr = sortNameParam && sortSetParam ? `${sortNameParam},${sortSetParam}` : DEFAULT_PARAMS.sort
    state.params = {
      ...state.params,
      sort: sortStr,
    }
  },
  SET_DATES(state, dates) {
    if (dates) {
      state.params = {
        ...state.params,
        dateFrom: dates[0],
        dateTo: dates[1],
      }
    } else {
      delete state.params.dateFrom
      delete state.params.dateTo
      state.params = {...state.params}
    }
  },
  SET_LOADING(state, {type, loading}) {
    switch (type) {
      case TYPE_LOADING_ITEMS:
        state.loading = loading
        break
      case TYPE_LOADING_ACTION:
        state.actionLoading = loading
        break
    }
  },
  RESET_PARAMS(state) {
    state.params = {...DEFAULT_PARAMS}
    state.isShowFilterBar = false
    state.selectedAppFilter = {}
    state.selectedTableFilters = []
  },
  SET_CONTRACT(state, contract) {
    state.contract = contract
  },
  SET_PAYMENT_INFO(state, info) {
    state.paymentInfo = info
  },
  SET_CLIENTS_COUNTRY(state, data) {
    state.countryItems = data?.sort((a, b) => {
      if (a.value < b.value) {
        return -1
      }
      if (a.value > b.value) {
        return 1
      }
      return 0
    })
  },
  SET_APP_FILTER(state, {data = {}, isApi = false}) {
    state.isShowFilterBar = true

    if (isApi) {
      state.selectedAppFilter = data
    } else {
      const {value, placeholder, filterType} = data
      state.selectedAppFilter = {
        name: value,
        value: '',
        placeholder: placeholder ?? value,
        type: filterType,
      }
    }
  },
  SET_TABLE_FILTERS(state, data = undefined) {
    const {name} = state.selectedAppFilter
    const filtersToUpdate = data ?? state.selectedTableFilters

    if (!isDuplicateFilter(state.selectedTableFilters, name)) {
      addFilter(state, filtersToUpdate, data)
    } else {
      updateFilter(filtersToUpdate, state.selectedAppFilter)
    }

    const params = createParams(state.selectedTableFilters)

    // Сохраняем параметры и сбрасываем номер страницы
    this.commit('finance/SET_PARAMS', params)
    this.commit('finance/SET_PAGE_NUMBER', {page: 1})
  },
  DELETE_TABLE_FILTERS(state, data) {
    state.selectedTableFilters = state.selectedTableFilters.filter((a) => a.name !== data.name)
    this.commit('finance/DELETE_PARAM', data)
    if (state.selectedTableFilters.length === 0) state.isShowFilterBar = false
  },
}

const actions = {
  async GET_ITEMS({commit, dispatch}, {params, type}) {
    commit('SET_ITEMS', [])
    commit('SET_LOADING', {type: TYPE_LOADING_ITEMS, loading: true})
    let url = ''
    switch (type) {
      case financeType.FINANCE_TYPE_INVOICES:
        url = 'finance/invoice/all'
        break
      case financeType.FINANCE_TYPE_ACTS:
        url = 'finance/act-work-performed/all'
        break
      case financeType.FINANCE_TYPE_CONTRACTS:
        url = 'finance/user-contract/all'
        break
      case financeType.FINANCE_USER_TYPE_PAYMENTS:
        url = 'finance/user/invoice/all'
        break
      case financeType.FINANCE_USER_TYPE_DEBIT:
        url = 'finance/user/write-off-balance'
        params.sort = 'date,desc'
        break
      case financeType.FINANCE_USER_TYPE_ACTS:
        url = 'finance/user/act-work-performed/all'
        break
      case financeType.EXCHANGE_RATE_TYPE:
        url = 'exchange-rate/all'
        break
      case financeType.FINANCE_TYPE_VAT:
        url = 'vat/all'
        break
      default:
        url = ''
    }
    await getItems(commit, dispatch, TYPE_LOADING_ITEMS, url, params)
  },
  async REPLENISH_BALANCE({commit, dispatch}, formData) {
    commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: true})
    try {
      const res = await axios.put('finance/replenish-user-balance', {
        ...formData,
      })
      if (res.status === 200) {
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.successDefault'), {
          root: true,
        })
        return true
      }
    } catch (err) {
      await customErrors(err, dispatch)
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: false})
    }
  },
  async SIGN_DOCUMENT({commit, dispatch}, params) {
    try {
      const res = await axios.put('finance/user-contract/sign', null, {params})
      const {status} = res
      if (status === 200) {
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.signDocument'), {
          root: true,
        })
        return true
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
  async GET_PAYMENT_INFO({commit, dispatch}, {type, id}) {
    let url
    let typeMutation
    switch (type) {
      case 'CONTRACT':
        url = id ? `finance/user-contract/${id}` : 'finance/user/contract'
        typeMutation = 'SET_CONTRACT'
        break
      case 'PAYMENT':
        url = id ? `finance/payment-info/${id}` : 'finance/user/payment-info'
        typeMutation = 'SET_PAYMENT_INFO'
        break
      case 'COUNTRY':
        url = 'dictionary/clients/country/read-all'
        typeMutation = 'SET_CLIENTS_COUNTRY'
        break
      default:
        url = ''
        typeMutation = ''
    }
    try {
      const res = await axios.get(url)
      const {data, status} = res
      if (status === 200) {
        commit(typeMutation, data)
      }
    } catch (err) {
      commit(typeMutation, null)
      await customErrors(err, dispatch)
    }
  },
  async GET_RECALCULATION_INFO({commit, dispatch}, id) {
    try {
      const res = await axios.get(`finance/invoice/recalculation/${id}`)
      const {data, status} = res
      if (status === 200) {
        return data
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
  async CREATE_PAYMENT_INFO({commit, dispatch}, formData) {
    const url = 'finance/user/payment-info'
    return await actionItem(dispatch, url, 'toast.savePayment', formData)
  },
  async INVOICE_PAYMENT_INFO({dispatch}, amountData) {
    const url = 'finance/user/invoice/init'
    return await actionItem(dispatch, url, 'toast.formAccount', amountData)
  },
  async UPDATE_PAYMENT_INFO({commit, dispatch}, formData) {
    const url = 'finance/user/payment-info/update'
    return await editItem(commit, dispatch, TYPE_LOADING_ACTION, url, formData, 'toast.savePayment')
  },
  async UPDATE_VAT({commit, dispatch}, {id, params}) {
    commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: true})
    try {
      const res = await axios.put(`vat/update/${id}`, {...params})
      const {status} = res
      if (status === 200) {
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.successEdit'), {
          root: true,
        })
        return true
      }
    } catch (err) {
      await customErrors(err, dispatch)
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: false})
    }
  },
  async CREATE_VAT({commit, dispatch}, params) {
    commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: true})
    try {
      const res = await axios.post('vat/create', {...params})
      const {status} = res
      if (status === 200) {
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.successCreate'), {
          root: true,
        })
        return true
      }
    } catch (err) {
      await customErrors(err, dispatch)
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: false})
    }
  },
  async FINANCE_ACT_ACTION({commit, dispatch}, {params, config}) {
    commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: true})
    try {
      const res = await axios.put(config.url, {...params})
      const {status} = res
      if (status === 200) {
        dispatch('SHOW_TOAST_SUCCESS', i18n.t(config.message), {
          root: true,
        })
        return true
      }
    } catch (err) {
      await customErrors(err, dispatch)
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: false})
    }
  },
  async REPEAT_ORD({commit, dispatch}, params) {
    try {
      const res = await axios.put('finance/user/payment-info/ord/repeat', {...params})
      const {status} = res
      if (status === 200) {
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.successRepeat'), {
          root: true,
        })
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
