<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant="miniDrawer"
    :mobile-breakpoint="checkXs ? undefined : 0"
    mini-variant-width="76"
    width="285"
    class="drawer-wrapper elevation-1"
    app
    floating
  >
    <div class="d-flex align-center my-10">
      <v-icon
        :class="miniDrawer ? 'mx-13' : 'mx-12'"
        :color="miniDrawer ? 'activeLink' : ''"
        @click="openDrawer"
      >
        mdi-menu
      </v-icon>
    </div>
    <v-list v-if="items.length" class="drawer-wrapper__menu pa-0">
      <template v-for="item in items">
        <v-list-item
          :key="item.page"
          class="nav-item"
          :to="item.href ? '' : item.route"
          :href="item.href ? setHrefLocale(item.href) : '#'"
          :target="item.href ? '_blank' : '_self'"
          :class="{'active-route': checkActiveRoute(item)}"
          :ripple="false"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="navItem">{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <div v-else class="px-5 pt-10">
      <h5 v-if="!miniDrawer" class="text-h5 text-center">{{ $t('notification.pages') }}</h5>
    </div>
  </v-navigation-drawer>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

import adminRoutes from '@/helpers/routes/admin'
import advertiserRoutes from '@/helpers/routes/advertiser'
import managerRoutes from '@/helpers/routes/manager'
import {setItem, getItem} from '@/helpers/persistantStorage'

export default {
  name: 'LeftDrawer',
  inheritAttrs: false,
  data: () => ({
    items: [
      {
        page: 'client',
        title: 'menu.clients',
        icon: 'mdi-account-supervisor',
        permission: 'CLIENT_',
      },
      {
        page: 'security',
        title: 'menu.security',
        icon: 'mdi-account-lock',
        permission: 'SECURITY_',
      },
      {
        page: 'dashboard',
        title: 'menu.dashboard',
        icon: 'mdi-view-dashboard',
        permission: 'DASHBOARD_',
      },
      {
        page: 'statistic',
        title: 'menu.statistics',
        icon: 'mdi-chart-line',
        permission: 'STATISTIC_',
      },
      {
        page: 'campaign',
        title: 'menu.campaigns',
        icon: 'mdi-store-outline',
        permission: 'CAMPAIGN_',
      },
      {
        page: 'creative',
        title: 'menu.creatives',
        icon: 'mdi-monitor',
        permission: 'CREATIVE_',
      },
      {
        page: 'target',
        title: 'menu.targets',
        icon: 'mdi-target',
        permission: 'TARGET_',
      },
      {
        page: 'segment',
        title: 'menu.segments',
        icon: 'mdi-segment',
        permission: 'SEGMENT_',
      },
      {
        page: 'rule',
        title: 'menu.rules',
        icon: 'mdi-book-open',
        permission: 'RULE_',
      },
      {
        page: 'integration',
        title: 'menu.integrations',
        icon: 'mdi-cog-transfer',
        permission: 'INTEGRATION_',
      },
      {
        page: 'doc',
        title: 'menu.docs',
        icon: 'mdi-file-document-outline',
        permission: 'DOCS_',
        href: process.env.VUE_APP_WIKI_URL,
      },
      {
        page: 'tag',
        title: 'menu.tags',
        icon: 'mdi-tag-outline',
        permission: 'TAG_',
      },
      {
        page: 'finance',
        title: 'menu.finance',
        icon: 'mdi-cash-multiple',
        permission: 'FINANCES_',
      },
    ],
    drawer: true,
    miniDrawer: false,
  }),
  computed: {
    ...mapState({
      permissions: (state) => state.auth.permissions,
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isManager: 'auth/isManager',
      isAdvertiser: 'auth/isAdvertiser',
      isAdvertiserPro: 'auth/isAdvertiserPro',
      isAdvertiserManager: 'auth/isAdvertiserManager',
    }),
    checkXs() {
      return this.$vuetify.breakpoint.xsOnly
    },
    checkSm() {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
  created() {
    this.loadMenu()
    if (this.checkSm) {
      this.miniDrawer = true
    } else {
      this.miniDrawer = !!getItem('nt_menu_action')
    }
  },
  methods: {
    loadMenu() {
      let routePages = []
      switch (true) {
        case this.isAdmin:
          routePages = adminRoutes
          break
        case this.isManager:
          routePages = managerRoutes
          break
        case this.isAdvertiser || this.isAdvertiserPro || this.isAdvertiserManager:
          // this.items = this.items.filter((a) => a.page !== 'dashboard')
          routePages = advertiserRoutes
          break
        default:
          routePages = []
          break
      }
      this.items = this.parseMenuWithPermissions(this.permissions, this.items, routePages)
    },
    parseMenuWithPermissions(permissions, items, routes) {
      items = items
        .filter((a) => permissions?.some((b) => b.startsWith(a.permission)))
        .map((c) => {
          const {title} = c
          return {
            ...c,
            title,
            route: routes[c.page]?.route,
            order: routes[c.page]?.order,
          }
        })
        .sort((d, e) => d.order - e.order)
      return items
    },
    checkActiveRoute(item) {
      const compareValue = this.$route.fullPath.split('/')[2]
      return compareValue ? compareValue.indexOf(item.page.toLowerCase()) !== -1 : false
    },
    openDrawer() {
      this.miniDrawer = !this.miniDrawer
      setItem('nt_menu_action', this.miniDrawer)
    },
    setHrefLocale(href) {
      return `${href}/${this.$i18n.locale}`
    },
  },
}
</script>
