export const CURRENCY_ITEMS = [
  {
    text: 'USD',
    value: 'USD',
  },
  {
    text: 'EUR',
    value: 'EUR',
  },
  {
    text: 'RUB',
    value: 'RUB',
  },
]

export const currencySymbols = {
  USD: '$',
  EUR: '€',
  RUB: '₽',
}
