import axios from '@/plugins/axios'
import customErrors from '@/store/error'

const TYPE_LOADING_STATE = 'STATE'
const TYPE_LOADING_UPDATE = 'UPDATE'

const DEFAULT_PARAMS = {
  page: 0,
  size: 20,
  sort: 'id,desc',
}

const state = () => ({
  filters: undefined,
  loading: false,
  updateLoading: false,
  params: {...DEFAULT_PARAMS},
})

const mutations = {
  SET_FILTERS(state, data) {
    state.filters = data
  },
  SET_PARAMS(state, params) {
    state.params = {
      ...state.params,
      ...params,
    }
  },
  RESET_PARAMS(state) {
    state.params = {...DEFAULT_PARAMS}
  },
  SET_LOADING(state, {type, loading}) {
    switch (type) {
      case TYPE_LOADING_STATE:
        state.loading = loading
        break
      case TYPE_LOADING_UPDATE:
        state.updateLoading = loading
        break
    }
  },
}

const actions = {
  async GET_FILTERS({commit, dispatch}, page) {
    commit('SET_LOADING', {type: TYPE_LOADING_STATE, loading: true})
    try {
      const res = await axios.get(`ui/state/${page}`)
      const {data, status} = res
      if (status === 200) {
        commit('SET_FILTERS', data)
      }
    } catch (err) {
      commit('SET_FILTERS', undefined)
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING_STATE, loading: false})
    }
  },
  async UPDATE_FILTERS({commit, dispatch}, {page, params}) {
    commit('SET_LOADING', {type: TYPE_LOADING_UPDATE, loading: true})
    try {
      await axios.put(`ui/state/${page}`, {...params})
    } catch (err) {
      await customErrors(err, dispatch)
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING_UPDATE, loading: false})
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
