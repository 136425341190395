export const FINANCE_TYPE_INVOICES = 'INVOICES'
export const FINANCE_TYPE_ACTS = 'ACTS'
export const FINANCE_TYPE_CONTRACTS = 'CONTRACTS'
export const EXCHANGE_RATE_TYPE = 'EXCHANGE_RATE'
export const FINANCE_TYPE_VAT = 'VAT'
export const FINANCE_INVOICE_ACTION_UPLOAD = 'INVOICE_UPLOAD'
export const FINANCE_INVOICE_ACTION_BALANCE = 'INVOICE_BALANCE'
export const FINANCE_INVOICE_ACTION_FILE_REPLACE = 'INVOICE_FILE_REPLACE'
export const FINANCE_ACT_ACTION_UPLOAD = 'ACT_UPLOAD'
export const FINANCE_ACT_ACTION_FILE_REPLACE = 'ACT_FILE_REPLACE'
export const FINANCE_ACT_ACTION_REPEAT = 'ACT_REPEAT'
export const FINANCE_CONTRACT_ACTION_FILE_SIGN = 'CONTRACT_FILE_SIGN'
export const FINANCE_CONTRACT_ACTION_DOWNLOAD = 'CONTRACT_DOWNLOAD'
export const FINANCE_CONTRACT_ACTION_FILE_REPLACE = 'CONTRACT_FILE_REPLACE'
export const FINANCE_USER_TYPE_PAYMENTS = 'USER_PAYMENTS'
export const FINANCE_USER_TYPE_DEBIT = 'USER_DEBIT'
export const FINANCE_USER_TYPE_ACTS = 'USER_ACTS'
export const FINANCE_PAYMENT_ACTION_DOWNLOAD = 'PAYMENT_DOWNLOAD'
export const FINANCE_ACT_ACTION_DOWNLOAD = 'ACT_DOWNLOAD'
export const EXCHANGE_RATE_ACTION_BANK = 'BANK'
export const DOWNLOAD_ACTION = 'DOWNLOAD'
export const FINANCE_VAT_ACTION_CREATE = 'VAT_CREATE'
export const FINANCE_VAT_ACTION_UPDATE = 'VAT_UPDATE'
