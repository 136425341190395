// Проверка на дубликат фильтра
export function isDuplicateFilter(filters, name) {
  return filters.some((filter) => filter.name === name)
}

// Добавление нового фильтра
export function addFilter(state, filtersToUpdate, data) {
  const {selectedAppFilter} = state
  state.selectedTableFilters =
    data === undefined ? [...state.selectedTableFilters, {...selectedAppFilter}] : [...filtersToUpdate]
}

// Обновление существующего фильтра
export function updateFilter(filtersToUpdate, selectedAppFilter) {
  const filterIndex = filtersToUpdate.findIndex((filter) => filter.name === selectedAppFilter.name)
  if (filterIndex !== -1) {
    Object.assign(filtersToUpdate[filterIndex], selectedAppFilter)
  }
}

// Формирование объекта параметров
export function createParams(filters) {
  return filters.reduce((acc, filter) => {
    acc[filter.name] = filter.isObjectValue ? filter.value.value : filter.value
    return acc
  }, {})
}
