import {createItem, deleteItem, editItem, getItemById, getItems} from '@/store/main'
import axios from '@/plugins/axios'
import customErrors from '@/store/error'
import convertWithRootToArray from '@/helpers/convertWithRootToArray'
import {addFilter, createParams, isDuplicateFilter, updateFilter} from '@/helpers/filterHelper'

const TYPE_LOADING = 'RULE'
const TYPE_LOADING_ACTION = 'ACTION'
const TYPE_TOPIC = 'TOPIC'

const DEFAULT_PARAMS = {
  page: 0,
  size: 20,
  sort: 'id,desc',
}

const DEFAULT_TOPIC_PARAMS = {
  page: 0,
  size: 50,
}

const state = () => ({
  items: [],
  item: null,
  loading: false,
  actionLoading: false,
  dataPagination: {},
  params: {...DEFAULT_PARAMS},
  topicItems: [],
  topicLoading: false,
  topicPagination: {},
  topicParams: DEFAULT_TOPIC_PARAMS,
  isShowFilterBar: false,
  selectedAppFilter: {},
  selectedTableFilters: [],
  isResetSortParams: false,
})

const getters = {
  permission: (state, getters, rootState) => {
    return {
      read: rootState.auth?.permissions?.some((a) => a === 'RULE_READ'),
      readAll: rootState.auth?.permissions?.some((a) => a === 'RULE_READ_ALL'),
      new: rootState.auth.permissions?.some((a) => a === 'RULE_CREATE'),
      newForClient: rootState.auth.permissions?.some((a) => a === 'RULE_CREATE_FOR_CLIENT'),
      update: rootState.auth?.permissions?.some((a) => a === 'RULE_UPDATE'),
      remove: rootState.auth?.permissions?.some((a) => a === 'RULE_REMOVE'),
      createCost: rootState.auth?.permissions?.some((a) => a === 'RULE_CREATE_COST'),
    }
  },
}

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items
    setTimeout(() => {
      state.isResetSortParams = false
    }, 0)
  },
  SET_ITEM_BY_ID(state, item) {
    state.item = item
  },
  SET_PAGINATION(state, data) {
    state.dataPagination = {
      pageNumber: data.number + 1,
      totalPages: data.totalPages,
    }
  },
  SET_PARAMS(state, params) {
    state.params = {
      ...state.params,
      ...params,
    }
  },
  DELETE_PARAM(state, param) {
    delete state.params[param.name]
  },
  SET_PAGE_NUMBER(state, {page}) {
    state.params = {
      ...state.params,
      page: page - 1,
    }
  },
  SET_SORT_PARAMS(state, {sortNameParam, sortSetParam}) {
    const sortStr = sortNameParam && sortSetParam ? `${sortNameParam},${sortSetParam}` : DEFAULT_PARAMS.sort
    state.params = {
      ...state.params,
      sort: sortStr,
    }
  },
  SET_LOADING(state, {type, loading}) {
    switch (type) {
      case TYPE_LOADING:
        state.loading = loading
        break
      case TYPE_LOADING_ACTION:
        state.actionLoading = loading
        break
      case TYPE_TOPIC:
        state.topicLoading = loading
        break
    }
  },
  SET_TOPIC_ITEMS(state, {items, isStart}) {
    if (isStart) {
      state.topicItems = convertWithRootToArray(items)
    } else {
      state.topicItems = [...state.topicItems, ...convertWithRootToArray(items)]
    }
  },
  SET_TOPIC_PAGINATION(state, data) {
    state.topicPagination = data
  },
  SET_TOPIC_PAGE(state, isStart = false) {
    if (isStart) {
      state.topicParams = DEFAULT_TOPIC_PARAMS
    } else {
      state.topicParams = {
        ...DEFAULT_TOPIC_PARAMS,
        page: state.topicParams.page + 1,
      }
    }
  },
  RESET_PARAMS(state) {
    state.params = {...DEFAULT_PARAMS}
    state.isShowFilterBar = false
    state.selectedAppFilter = {}
    state.selectedTableFilters = []
    state.isResetSortParams = true
  },
  SET_APP_FILTER(state, {data = {}, isApi = false}) {
    state.isShowFilterBar = true

    if (isApi) {
      state.selectedAppFilter = data
    } else {
      const {value, placeholder, filterType} = data
      state.selectedAppFilter = {
        name: value,
        value: '',
        placeholder: placeholder ?? value,
        type: filterType,
      }
    }
  },
  SET_TABLE_FILTERS(state, data = undefined) {
    const {name} = state.selectedAppFilter
    const filtersToUpdate = data ?? state.selectedTableFilters

    if (!isDuplicateFilter(state.selectedTableFilters, name)) {
      addFilter(state, filtersToUpdate, data)
    } else {
      updateFilter(filtersToUpdate, state.selectedAppFilter)
    }

    const params = createParams(state.selectedTableFilters)

    // Сохраняем параметры и сбрасываем номер страницы
    this.commit('rule/SET_PARAMS', params)
    this.commit('rule/SET_PAGE_NUMBER', {page: 1})
  },
  DELETE_TABLE_FILTERS(state, data) {
    state.selectedTableFilters = state.selectedTableFilters.filter((a) => a.name !== data.name)
    this.commit('rule/DELETE_PARAM', data)
    if (state.selectedTableFilters.length === 0) state.isShowFilterBar = false
  },
}

const actions = {
  async GET_ITEMS({commit, dispatch}, params) {
    commit('SET_LOADING', {type: TYPE_LOADING, loading: true})
    commit('SET_ITEM_BY_ID', null)
    await getItems(commit, dispatch, TYPE_LOADING, 'rule/all', params)
  },
  async GET_ITEM_BY_ID({commit, dispatch}, id) {
    await getItemById(commit, dispatch, TYPE_LOADING, `rule/view/${id}`)
  },
  async CREATE_RULE({commit, dispatch}, formData) {
    const {userId, ...params} = formData
    const url = userId ? `rule/create/${userId}` : 'rule/create'
    return await createItem(commit, dispatch, TYPE_LOADING_ACTION, url, params)
  },
  async EDIT_RULE({commit, dispatch}, {id, formData}) {
    return await editItem(commit, dispatch, TYPE_LOADING_ACTION, `rule/update/${id}`, formData)
  },
  async DELETE_RULE({commit, dispatch}, id) {
    return await deleteItem(commit, dispatch, TYPE_LOADING_ACTION, `rule/remove/${id}`)
  },
  async GET_TOPIC_ITEMS({commit, dispatch}, {params, isStart}) {
    commit('SET_LOADING', {type: TYPE_TOPIC, loading: true})
    try {
      const res = await axios.get('dictionary/target/iab-categories/read-all', {params})
      if (res.status === 200) {
        const {content, number, last, totalPages, totalElements} = res.data
        commit('SET_TOPIC_ITEMS', {items: content, isStart})
        commit('SET_TOPIC_PAGINATION', {
          number,
          last,
          totalPages,
          totalElements,
        })
      }
    } catch (err) {
      await customErrors(err, dispatch)
    } finally {
      commit('SET_LOADING', {type: TYPE_TOPIC, loading: false})
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
