import i18n from '@/i18n/i18n'

export const setTranslateTitle = (column) => {
  const value = column.translateValue ?? column.value
  const key = `label.${value}`
  return i18n.te(key) ? i18n.t(key) : column.text || column.value
}

export const filteredFields = (data, fields) => {
  return data.filter((a) => fields.indexOf(a.value) > -1)
}

export const deletedFields = (data, fields) => {
  return data.filter((a) => fields.indexOf(a.value) < 0)
}
