<template>
  <v-btn
    v-bind="$attrs"
    :color="color"
    :class="titleColor"
    :small="small"
    :elevation="elevation"
    :height="height"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'IconButton',
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      default: 'blue',
    },
    titleColor: {
      type: String,
      default: 'white--text',
    },
    elevation: {
      type: Number,
      default: 1,
    },
    small: {
      type: Boolean,
      default: true,
    },
    height: {
      type: [String, Number],
      default: 'normal',
    },
  },
}
</script>
