import i18n from '@/i18n/i18n'

const convertWithRootToArray = (data, type = 'iabCategoryItems') => {
  return data.length
    ? data
        .map((a) => ({
          id: a.id,
          rootId: a.rootId,
          isRoot: a.isRoot,
          text: `${type}.${[a.name]}` ?? a.name,
          value: a.name,
        }))
        .sort((a, b) => {
          if (a.isRoot || b.isRoot) {
            return i18n.t(a.text).toLocaleLowerCase().localeCompare(i18n.t(b.text).toLocaleLowerCase())
          }
        })
    : []
}

export default convertWithRootToArray
