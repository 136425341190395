import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/plugins/axios'
import {getItem} from '@/helpers/persistantStorage'
import customErrors from '@/store/error'

import auth from '@/store/modules/auth'
import client from '@/store/modules/client'
import dictionary from '@/store/modules/dictionary'
import target from '@/store/modules/target'
import file from '@/store/modules/file'
import creative from '@/store/modules/creative'
import notification from '@/store/modules/notification'
import company from '@/store/modules/company'
import notUsedCreative from '@/store/modules/notUsedCreative'
import rule from '@/store/modules/rule'
import finance from '@/store/modules/finance'
import rate from '@/store/modules/rate'
import security from '@/store/modules/security'
import statistic from '@/store/modules/statistic'
import dashboard from '@/store/modules/dashboard'
import integration from '@/store/modules/integration'
import filters from '@/store/modules/filters'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    message: '',
    color: 'green',
    toaster: false,
    isCheckLocale: true,
    locale: getItem('nt_system_language') || 'en',
  },
  mutations: {
    SHOW_TOAST(state, payload) {
      state.toaster = payload
    },
    SHOW_TOAST_MESSAGE(state, message) {
      state.message = message
    },
    CHANGE_COLOR(state, color) {
      state.color = color
    },
    CHANGE_LOCALE(state, {check, locale}) {
      state.isCheckLocale = check
      state.locale = locale
    },
  },
  actions: {
    SHOW_TOAST_SUCCESS({commit}, message) {
      commit('CHANGE_COLOR', 'green')
      commit('SHOW_TOAST_MESSAGE', message)
      commit('SHOW_TOAST', true)
    },
    SHOW_TOAST_INFO({commit}, message) {
      commit('CHANGE_COLOR', 'orange darken-1')
      commit('SHOW_TOAST_MESSAGE', message)
      commit('SHOW_TOAST', true)
    },
    SHOW_TOAST_ERROR({commit}, message) {
      commit('CHANGE_COLOR', 'red darken-1')
      commit('SHOW_TOAST_MESSAGE', message)
      commit('SHOW_TOAST', true)
    },
    CLOSE_TOAST({commit}) {
      commit('SHOW_TOAST', false)
    },
    async GET_PUBLIC_INFO({commit, dispatch}, inn) {
      try {
        return await axios.get(`public/advertiser/${inn}`)
      } catch (err) {
        await customErrors(err, dispatch)
      }
    },
  },
  modules: {
    auth,
    client,
    dictionary,
    target,
    file,
    creative,
    notification,
    company,
    notUsedCreative,
    rule,
    finance,
    rate,
    security,
    statistic,
    dashboard,
    integration,
    filters,
  },
})
