import {ROLE} from '@/helpers/variables/roles'

const roles = [ROLE.ADMIN]

export default [
  {
    path: '/admin/dashboard',
    name: 'AdminDashboard',
    meta: {layout: 'main', auth: true, role: roles, permission: 'DASHBOARD_'},
    component: () => import(/* webpackChunkName: "admin-dashboard" */ '@/views/dashboard/Dashboard'),
  },
  {
    path: '/admin/clients',
    name: 'AdminClient',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CLIENT_'},
    component: () => import(/* webpackChunkName: "admin-clients" */ '@/views/clients/Clients'),
  },
  {
    path: '/admin/client/detail/:id',
    name: 'AdminClientDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CLIENT_'},
    component: () => import(/* webpackChunkName: "admin-client-detail" */ '@/views/clients/ClientDetail'),
  },
  {
    path: '/admin/client/payment/:id',
    name: 'AdminClientPayment',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CLIENT_'},
    component: () =>
      import(/* webpackChunkName: "admin-client-payment" */ '@/views/clients/ClientPayment.vue'),
  },
  {
    path: '/admin/client/new',
    name: 'AdminNewClient',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CLIENT_'},
    component: () => import(/* webpackChunkName: "admin-client-new" */ '@/views/security/SecurityNew.vue'),
  },
  {
    path: '/admin/statistics',
    name: 'AdminStatistic',
    meta: {layout: 'main', auth: true, role: roles, permission: 'STATISTIC_'},
    component: () => import(/* webpackChunkName: "admin-statistics" */ '@/views/statistics/Statistics'),
  },
  {
    path: '/admin/campaigns',
    name: 'AdminCampaign',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CAMPAIGN_'},
    component: () => import(/* webpackChunkName: "admin-campaigns" */ '@/views/campaigns/Campaigns'),
  },
  {
    path: '/admin/campaign/new',
    name: 'AdminNewCampaign',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CAMPAIGN_'},
    component: () => import(/* webpackChunkName: "admin-campaign-new" */ '@/views/campaigns/CampaignDetail'),
  },
  {
    path: '/admin/campaign/detail/:id',
    name: 'AdminCampaignDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CAMPAIGN_'},
    component: () =>
      import(/* webpackChunkName: "admin-campaign-detail" */ '@/views/campaigns/CampaignDetail'),
  },
  {
    path: '/admin/campaign/group/:id',
    name: 'AdminCampaignCreativesGroup',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CAMPAIGN_'},
    component: () =>
      import(
        /* webpackChunkName: "admin-campaign-creatives-group" */ '@/views/campaigns/CampaignCreativesGroup'
      ),
  },
  {
    path: '/admin/creatives',
    name: 'AdminCreative',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CREATIVE_'},
    component: () => import(/* webpackChunkName: "admin-creatives" */ '@/views/creatives/Creatives'),
  },
  {
    path: '/admin/creative/detail/:type/:id',
    name: 'AdminCreativeDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CREATIVE_'},
    component: () =>
      import(/* webpackChunkName: "admin-creative-detail" */ '@/views/creatives/CreativeDetail'),
  },
  {
    path: '/admin/creative/:type/new',
    name: 'AdminNewCreative',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CREATIVE_'},
    component: () => import(/* webpackChunkName: "admin-creative-new" */ '@/views/creatives/CreativeDetail'),
  },
  {
    path: '/admin/targets',
    name: 'AdminTarget',
    meta: {layout: 'main', auth: true, role: roles, permission: 'TARGET_'},
    component: () => import(/* webpackChunkName: "admin-targets" */ '@/views/targets/Targets'),
  },
  {
    path: '/admin/target/detail/:id',
    name: 'AdminTargetDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'TARGET_'},
    component: () => import(/* webpackChunkName: "admin-target-detail" */ '@/views/targets/TargetDetail'),
  },
  {
    path: '/admin/target/new',
    name: 'AdminNewTarget',
    meta: {layout: 'main', auth: true, role: roles, permission: 'TARGET_'},
    component: () => import(/* webpackChunkName: "admin-target-new" */ '@/views/targets/TargetDetail'),
  },
  {
    path: '/admin/segments',
    name: 'AdminSegment',
    meta: {layout: 'main', auth: true, role: roles, permission: 'SEGMENT_'},
    component: () => import(/* webpackChunkName: "admin-segments" */ '@/views/segments/Segments'),
  },
  {
    path: '/admin/rules',
    name: 'AdminRule',
    meta: {layout: 'main', auth: true, role: roles, permission: 'RULE_'},
    component: () => import(/* webpackChunkName: "admin-rules" */ '@/views/rules/Rules'),
  },
  {
    path: '/admin/rule/detail/:type/:id',
    name: 'AdminRuleDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'RULE_'},
    component: () => import(/* webpackChunkName: "admin-rule-detail" */ '@/views/rules/RuleDetail'),
  },
  {
    path: '/admin/rule/:type/new',
    name: 'AdminNewRule',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'RULE_'},
    component: () => import(/* webpackChunkName: "admin-rule-new" */ '@/views/rules/RuleDetail'),
  },
  {
    path: '/admin/integrations',
    name: 'AdminIntegration',
    meta: {layout: 'main', auth: true, role: roles, permission: 'INTEGRATION_'},
    component: () => import(/* webpackChunkName: "admin-integrations" */ '@/views/integrations/Integrations'),
  },
  {
    path: '/admin/integration/detail/:id',
    name: 'AdminIntegrationDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'INTEGRATION_'},
    component: () =>
      import(/* webpackChunkName: "admin-integration-detail" */ '@/views/integrations/IntegrationDetail.vue'),
  },
  {
    path: '/admin/integration/new',
    name: 'AdminNewIntegration',
    meta: {layout: 'main', auth: true, role: roles, permission: 'INTEGRATION_'},
    component: () =>
      import(/* webpackChunkName: "admin-integration-new" */ '@/views/integrations/IntegrationDetail.vue'),
  },
  {
    path: '/admin/tags',
    name: 'AdminTag',
    meta: {layout: 'main', auth: true, role: roles, permission: 'TAG_'},
    component: () => import(/* webpackChunkName: "admin-tags" */ '@/views/tags/Tags'),
  },
  {
    path: '/admin/finances',
    name: 'AdminFinances',
    meta: {layout: 'main', auth: true, role: roles, permission: 'FINANCES_'},
    component: () => import(/* webpackChunkName: "admin-finances" */ '@/views/finance/Finance.vue'),
  },
  {
    path: '/admin/security',
    name: 'AdminSecurity',
    meta: {layout: 'main', auth: true, role: roles, permission: 'SECURITY_'},
    component: () => import(/* webpackChunkName: "admin-security" */ '@/views/security/Security.vue'),
  },
  {
    path: '/admin/security/detail/:id',
    name: 'AdminSecurityDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'SECURITY_'},
    component: () =>
      import(/* webpackChunkName: "admin-security-detail" */ '@/views/security/SecurityDetail.vue'),
  },
  {
    path: '/admin/security/new',
    name: 'AdminNewSecurity',
    meta: {layout: 'main', auth: true, role: roles, permission: 'SECURITY_'},
    component: () => import(/* webpackChunkName: "admin-security-new" */ '@/views/security/SecurityNew.vue'),
  },
]
