const advertiserRoutes = {
  dashboard: {
    order: 1,
    route: {name: 'AdvertiserDashboard'},
  },
  client: {
    order: 2,
    route: {name: 'AdvertiserClient'},
  },
  creative: {
    order: 3,
    route: {name: 'AdvertiserCreative'},
  },
  target: {
    order: 4,
    route: {name: 'AdvertiserTarget'},
  },
  campaign: {
    order: 5,
    route: {name: 'AdvertiserCampaign'},
  },
  statistic: {
    order: 6,
    route: {name: 'AdvertiserStatistic'},
  },
  segment: {
    order: 7,
    route: {name: 'AdvertiserSegment'},
  },
  rule: {
    order: 8,
    route: {name: 'AdvertiserRule'},
  },
  finance: {
    order: 9,
    route: {name: 'AdvertiserFinances'},
  },
  security: {
    order: 10,
    route: {name: 'AdvertiserSecurity'},
  },
  doc: {
    order: 11,
  },
}

export default advertiserRoutes
