<template>
  <v-navigation-drawer
    v-model="isShow"
    right
    app
    touchless
    :width="!smAndDown ? 540 : '85%'"
    mobile-breakpoint="960"
    text-color="white"
  >
    <div class="d-flex justify-end mt-80 mt-sm-40 mt-md-0 pa-10">
      <v-icon @click="$emit('close')">mdi-close</v-icon>
    </div>
    <div class="px-5 px-sm-10 mt-sm-10">
      <h2 class="mb-10">{{ $t('title.wikiTitle') }}</h2>
      <div v-if="selectDocs.length">
        <v-expansion-panels v-model="visiblePanel" class="wiki-expansion" flat focusable>
          <v-expansion-panel v-for="link in selectDocs" :key="link.name">
            <v-expansion-panel-header class="wiki-button px-2 px-sm-8">
              <v-icon class="wiki-icon mr-2">mdi-file-document-outline</v-icon>
              <span>{{ $t(link.name) }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mt-13">
                <v-card class="mb-10">
                  <iframe width="100%" frameborder="0" height="530" :src="link.url"></iframe>
                </v-card>
                <div class="text-center">
                  <a :href="link.url" target="_blank" class="wiki-link">
                    {{ $t('service.openNewTab') }}
                  </a>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div v-else>{{ $t('service.listIsEmpty') }}</div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import {WIKI_LINKS} from '@/helpers/variables/wiki'

export default {
  main: 'WikiDrawer',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectDocs: [],
    visiblePanel: 1,
  }),
  computed: {
    isShow: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
  methods: {
    getPageType() {
      const fullPath = this.$route.fullPath
      const compareValue = fullPath.split('/')[2] ?? fullPath.split('/')[1]
      const index = WIKI_LINKS.findIndex((a) => compareValue.includes(a.key))
      const path = `${process.env.VUE_APP_WIKI_URL}/${this.$i18n.locale}`
      this.selectDocs = index > -1 ? WIKI_LINKS[index].docs.map((a) => ({...a, url: `${path}/${a.url}`})) : []
      const currentIndex = this.selectDocs.findIndex((a) => {
        if (a.type) {
          return fullPath.includes(a.type)
        }
      })
      this.$nextTick(() => {
        this.visiblePanel = currentIndex > -1 ? currentIndex : 0
      })
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        if (to !== from) {
          this.getPageType()
        }
      },
      deep: true,
      immediate: true,
    },
    '$i18n.locale'() {
      this.getPageType()
    },
  },
}
</script>
<style lang="scss" scoped>
.wiki-expansion {
  .v-item--active {
    .wiki-button {
      color: var(--v-blue-base);
    }
  }

  .wiki-button,
  .wiki-link {
    color: var(--v-anchor-base);
    transition: 0.3s;

    &:hover {
      color: var(--v-blue-base);
    }
  }

  .wiki-icon {
    color: inherit;
    flex: none;
  }
}
</style>
