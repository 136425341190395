import axios from '@/plugins/axios'
import customErrors from '@/store/error'
import i18n from '@/i18n/i18n'
import {archivedItem, createItem, editItem, getItemById, getItems} from '@/store/main'
import {currencySymbols} from '@/helpers/variables/currencies'
import {v4 as uuidv4} from 'uuid'
import {CAMPAIGN_COLUMNS} from '@/helpers/permissions/common'
import {addFilter, createParams, isDuplicateFilter, updateFilter} from '@/helpers/filterHelper'

const TYPE_LOADING = 'ITEMS'
const TYPE_LOADING_ACTION = 'ACTION'

const DEFAULT_PARAMS = {
  page: 0,
  size: 20,
  sort: 'id,desc',
}

const state = () => ({
  items: [],
  item: null,
  groupCreativeItems: [],
  loading: false,
  actionLoading: false,
  dataPagination: {},
  params: {...DEFAULT_PARAMS},
  isResetSortParams: false,
  isShowFilterBar: false,
  selectedAppFilter: {},
  selectedTableFilters: [],
})

const getters = {
  permission: (state, getters, rootState) => {
    return {
      read: rootState.auth?.permissions?.some((a) => a === 'CAMPAIGN_READ'),
      readAll: rootState.auth?.permissions?.some((a) => a === 'CAMPAIGN_READ_ALL'),
      new: rootState.auth.permissions?.some((a) => a === 'CAMPAIGN_CREATE'),
      newForClient: rootState.auth.permissions?.some((a) => a === 'CAMPAIGN_CREATE_FOR_CLIENT'),
      update: rootState.auth?.permissions?.some((a) => a === 'CAMPAIGN_UPDATE'),
      remove: rootState.auth?.permissions?.some((a) => a === 'CAMPAIGN_REMOVE'),
      viewExchanges: rootState.auth?.permissions?.some((a) => a === 'CAMPAIGN_EXCHANGES_READ_ALL'),
      viewColumns: rootState.auth?.permissions?.filter((a) => CAMPAIGN_COLUMNS.some((b) => a === b)),
    }
  },
}

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items.map((a) => {
      const currency = currencySymbols[a.currency] ?? a.currency
      return {
        ...a,
        dailyBudget: `${a.dailyBudget} ${currency}`,
        totalBudget: `${a.totalBudget} ${currency}`,
        statSpend: `${a.statSpend} ${currency}`,
        cpc: `${a.cpc} ${currency}`,
        cpm: `${a.cpm} ${currency}`,
        cpv: `${a.cpv} ${currency}`,
        vtr: `${a.vtr} %`,
        completionRate: `${a.completionRate} %`,
        fillRate: `${a.fillRate} %`,
      }
    })
    setTimeout(() => {
      state.isResetSortParams = false
    }, 0)
  },
  SET_ITEM_BY_ID(state, item) {
    state.item = item
  },
  SET_GROUP_CREATIVES(state, items) {
    state.groupCreativeItems = items.map((a) => {
      const currency = currencySymbols[a.currency] ?? a.currency
      const updatedCreatives = a.creatives.map((b) => ({
        ...b,
        statSpend: `${b.statSpend} ${currency}`,
        cpc: `${b.cpc} ${currency}`,
        cpm: `${b.cpm} ${currency}`,
        cpv: `${b.cpv} ${currency}`,
        vtr: `${b.vtr} %`,
        completionRate: `${b.completionRate} %`,
        fillRate: `${b.fillRate} %`,
      }))

      return {
        ...a,
        creatives: updatedCreatives,
        uuid: uuidv4(),
        maxPrice: `${a.maxPrice} ${currency}`,
        statSpend: `${a.statSpend} ${currency}`,
        cpc: `${a.cpc} ${currency}`,
        cpm: `${a.cpm} ${currency}`,
        cpv: `${a.cpv} ${currency}`,
        vtr: `${a.vtr} %`,
        completionRate: `${a.completionRate} %`,
        fillRate: `${a.fillRate} %`,
      }
    })
  },
  SET_PAGINATION(state, data) {
    state.dataPagination = {
      pageNumber: data.number + 1,
      totalPages: data.totalPages,
    }
  },
  SET_PARAMS(state, params) {
    state.params = {
      ...state.params,
      ...params,
    }
  },
  DELETE_PARAM(state, param) {
    delete state.params[param.name]
  },
  SET_PAGE_NUMBER(state, {page}) {
    state.params = {
      ...state.params,
      page: page - 1,
    }
  },
  SET_SORT_PARAMS(state, {sortNameParam, sortSetParam}) {
    const sortStr = sortNameParam && sortSetParam ? `${sortNameParam},${sortSetParam}` : DEFAULT_PARAMS.sort
    state.params = {
      ...state.params,
      sort: sortStr,
    }
  },
  SET_DATES(state, dates) {
    state.params = {
      ...state.params,
      dateFrom: dates[0],
      dateTo: dates[1],
    }
  },
  SET_LOADING(state, {type, loading}) {
    switch (type) {
      case TYPE_LOADING:
        state.loading = loading
        break
      case TYPE_LOADING_ACTION:
        state.actionLoading = loading
        break
    }
  },
  RESET_PARAMS(state) {
    const dateFrom = state.params.dateFrom
    const dateTo = state.params.dateTo
    state.params = {...DEFAULT_PARAMS, dateFrom, dateTo}
    state.isShowFilterBar = false
    state.selectedAppFilter = {}
    state.selectedTableFilters = []
    state.isResetSortParams = true
  },
  SET_APP_FILTER(state, {data = {}, isApi = false}) {
    state.isShowFilterBar = true

    if (isApi) {
      state.selectedAppFilter = data
    } else {
      const {value, placeholder, filterType} = data
      state.selectedAppFilter = {
        name: value,
        value: '',
        placeholder: placeholder ?? value,
        type: filterType,
      }
    }
  },
  SET_TABLE_FILTERS(state, data = undefined) {
    const {name} = state.selectedAppFilter
    const filtersToUpdate = data ?? state.selectedTableFilters

    if (!isDuplicateFilter(state.selectedTableFilters, name)) {
      addFilter(state, filtersToUpdate, data)
    } else {
      updateFilter(filtersToUpdate, state.selectedAppFilter)
    }

    const params = createParams(state.selectedTableFilters)

    // Сохраняем параметры и сбрасываем номер страницы
    this.commit('company/SET_PARAMS', params)
    this.commit('company/SET_PAGE_NUMBER', {page: 1})
  },
  DELETE_TABLE_FILTERS(state, data) {
    state.selectedTableFilters = state.selectedTableFilters.filter((a) => a.name !== data.name)
    this.commit('company/DELETE_PARAM', data)
    if (state.selectedTableFilters.length === 0) state.isShowFilterBar = false
  },
}

const actions = {
  async GET_ITEMS({commit, dispatch}, params) {
    commit('SET_LOADING', {type: TYPE_LOADING, loading: true})
    await getItems(commit, dispatch, TYPE_LOADING, 'campaign/all', params)
  },
  async GET_ITEM_BY_ID({commit, dispatch}, id) {
    await getItemById(commit, dispatch, TYPE_LOADING, `campaign/view/${id}`)
  },
  async GET_GROUP_CREATIVES({commit, dispatch}, params) {
    commit('SET_LOADING', {type: TYPE_LOADING, loading: true})
    // commit('SET_GROUP_CREATIVES', [])
    try {
      const {id, ...rest} = params
      const res = await axios.get(`campaign/${id}/creative-group/all`, {params: rest})
      if (res.status === 200) {
        commit('SET_GROUP_CREATIVES', res.data)
      }
    } catch (err) {
      await customErrors(err, dispatch)
      commit('SET_GROUP_CREATIVES', [])
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING, loading: false})
    }
  },
  async CREATE_CAMPAIGN({commit, dispatch}, formData) {
    const {userId, ...params} = formData
    const url = userId ? `campaign/create/${userId}` : 'campaign/create'
    return await createItem(commit, dispatch, TYPE_LOADING_ACTION, url, params)
  },
  async EDIT_CAMPAIGN({commit, dispatch}, {id, formData}) {
    return await editItem(commit, dispatch, TYPE_LOADING_ACTION, `campaign/update/${id}`, formData)
  },
  // async DELETE_CAMPAIGN({commit, dispatch}, id) {
  //   return await deleteItem(commit, dispatch, TYPE_LOADING_ACTION, `campaign/remove/${id}`)
  // },
  async ARCHIVE_CAMPAIGN({commit, dispatch}, id) {
    return await archivedItem(commit, dispatch, TYPE_LOADING_ACTION, `campaign/archiving/${id}`)
  },
  async CHANGE_STATUS({commit, dispatch}, formData) {
    try {
      const res = await axios.put(`campaign/change-status/${formData.id}`, {
        status: formData.status,
      })
      if (res.status === 200) {
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.changeStatus', {status: formData.status}), {
          root: true,
        })
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
  async CREATE_CAMPAIGN_GROUP_CREATIVE({commit, dispatch}, formData) {
    const {id, ...params} = formData
    const url = `campaign/${id}/creative-group/create`
    return await createItem(commit, dispatch, TYPE_LOADING_ACTION, url, params)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
