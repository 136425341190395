import {getItems, getItemById, createItem, editItem, deleteItem} from '@/store/main'
import {TARGET_PANELS} from '@/helpers/permissions/common'
import {addFilter, createParams, isDuplicateFilter, updateFilter} from '@/helpers/filterHelper'

const TYPE_LOADING = 'TARGET'
const TYPE_LOADING_ACTION = 'ACTION'

const DEFAULT_PARAMS = {
  page: 0,
  size: 20,
  sort: 'id,desc',
}

const state = () => ({
  items: [],
  item: null,
  loading: false,
  actionLoading: false,
  dataPagination: {},
  params: {...DEFAULT_PARAMS},
  isShowFilterBar: false,
  selectedAppFilter: {},
  selectedTableFilters: [],
  isResetSortParams: false,
})

const getters = {
  permission: (state, getters, rootState) => {
    return {
      read: rootState.auth?.permissions?.some((a) => a === 'TARGET_READ'),
      readAll: rootState.auth?.permissions?.some((a) => a === 'TARGET_READ_ALL'),
      new: rootState.auth.permissions?.some((a) => a === 'TARGET_CREATE'),
      newForClient: rootState.auth.permissions?.some((a) => a === 'TARGET_CREATE_FOR_CLIENT'),
      update: rootState.auth?.permissions?.some((a) => a === 'TARGET_UPDATE'),
      remove: rootState.auth?.permissions?.some((a) => a === 'TARGET_REMOVE'),
      addAdvanced: rootState.auth?.permissions?.some((a) => a === 'TARGET_ADD_ADVANCED'),
      verificationTab: rootState.auth?.permissions?.some(
        (a) => a === 'TARGET_ADD_ADVANCED_VERIFICATION_SERVICES',
      ),
      viewColumns: rootState.auth?.permissions?.filter((a) => TARGET_PANELS.some((b) => a === b)),
    }
  },
}

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items
    setTimeout(() => {
      state.isResetSortParams = false
    }, 0)
  },
  SET_ITEM_BY_ID(state, item) {
    state.item = item
  },
  SET_PAGINATION(state, data) {
    state.dataPagination = {
      pageNumber: data.number + 1,
      totalPages: data.totalPages,
    }
  },
  SET_PARAMS(state, params) {
    state.params = {
      ...state.params,
      ...params,
    }
  },
  DELETE_PARAM(state, param) {
    delete state.params[param.name]
  },
  SET_PAGE_NUMBER(state, {page}) {
    state.params = {
      ...state.params,
      page: page - 1,
    }
  },
  SET_SORT_PARAMS(state, {sortNameParam, sortSetParam}) {
    const sortStr = sortNameParam && sortSetParam ? `${sortNameParam},${sortSetParam}` : DEFAULT_PARAMS.sort
    state.params = {
      ...state.params,
      sort: sortStr,
    }
  },
  SET_LOADING(state, {type, loading}) {
    switch (type) {
      case TYPE_LOADING:
        state.loading = loading
        break
      case TYPE_LOADING_ACTION:
        state.actionLoading = loading
        break
    }
  },
  RESET_PARAMS(state) {
    state.params = {...DEFAULT_PARAMS}
    state.isShowFilterBar = false
    state.selectedAppFilter = {}
    state.selectedTableFilters = []
    state.isResetSortParams = true
  },
  SET_APP_FILTER(state, {data = {}, isApi = false}) {
    state.isShowFilterBar = true

    if (isApi) {
      state.selectedAppFilter = data
    } else {
      const {value, placeholder, filterType} = data
      state.selectedAppFilter = {
        name: value,
        value: '',
        placeholder: placeholder ?? value,
        type: filterType,
      }
    }
  },
  SET_TABLE_FILTERS(state, data = undefined) {
    const {name} = state.selectedAppFilter
    const filtersToUpdate = data ?? state.selectedTableFilters

    if (!isDuplicateFilter(state.selectedTableFilters, name)) {
      addFilter(state, filtersToUpdate, data)
    } else {
      updateFilter(filtersToUpdate, state.selectedAppFilter)
    }

    const params = createParams(state.selectedTableFilters)

    // Сохраняем параметры и сбрасываем номер страницы
    this.commit('target/SET_PARAMS', params)
    this.commit('target/SET_PAGE_NUMBER', {page: 1})
  },
  DELETE_TABLE_FILTERS(state, data) {
    state.selectedTableFilters = state.selectedTableFilters.filter((a) => a.name !== data.name)
    this.commit('target/DELETE_PARAM', data)
    if (state.selectedTableFilters.length === 0) state.isShowFilterBar = false
  },
}

const actions = {
  async GET_ITEMS({commit, dispatch}, params) {
    commit('SET_LOADING', {type: TYPE_LOADING, loading: true})
    commit('SET_ITEM_BY_ID', null)
    await getItems(commit, dispatch, TYPE_LOADING, 'target/all', params)
  },
  async GET_ITEM_BY_ID({commit, dispatch}, id) {
    await getItemById(commit, dispatch, TYPE_LOADING, `target/view/${id}`)
  },
  async CREATE_TARGET({commit, dispatch}, formData) {
    const {userId, ...params} = formData
    const url = userId ? `target/create/${userId}` : 'target/create'
    return await createItem(commit, dispatch, TYPE_LOADING_ACTION, url, params)
  },
  async EDIT_TARGET({commit, dispatch}, {id, formData}) {
    return await editItem(commit, dispatch, TYPE_LOADING_ACTION, `target/update/${id}`, formData)
  },
  async DELETE_TARGET({commit, dispatch}, id) {
    return await deleteItem(commit, dispatch, TYPE_LOADING_ACTION, `target/remove/${id}`)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
