import axios from '@/plugins/axios'
import customErrors from '@/store/error'
import convertObjToArray from '@/helpers/convertObjToArray'
import {deleteItem, editItem, getItemById, getItems} from '@/store/main'
import {currencySymbols} from '@/helpers/variables/currencies'
import {addFilter, createParams, isDuplicateFilter, updateFilter} from '@/helpers/filterHelper'

const TYPE_LOADING_USER = 'USER'
const TYPE_LOADING_USER_ACTION = 'ACTION'

const DEFAULT_PARAMS = {
  page: 0,
  size: 20,
  sort: 'id,desc',
}

const state = () => ({
  items: [],
  item: null,
  loading: false,
  actionLoading: false,
  dataPagination: {},
  userIdItems: [],
  userNameItems: [],
  params: {...DEFAULT_PARAMS},
  isShowFilterBar: false,
  selectedAppFilter: {},
  selectedTableFilters: [],
  isResetSortParams: false,
})

const getters = {
  permission: (state, getters, rootState) => {
    return {
      read: rootState.auth?.permissions?.some((a) => a === 'CLIENT_READ'),
      readAll: rootState.auth?.permissions?.some((a) => a === 'CLIENT_READ_ALL'),
      new: rootState.auth.permissions?.some((a) => a === 'CLIENT_CREATE'),
      update: rootState.auth?.permissions?.some((a) => a === 'CLIENT_UPDATE'),
      updatePayment: rootState.auth?.permissions?.some((a) => a === 'CLIENT_PAYMENT_INFO_UPDATE'),
    }
  },
}

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items.map((a) => {
      const currency = currencySymbols[a.currency] ?? a.currency
      return {
        ...a,
        balance: `${a.balance} ${currency}`,
      }
    })
    setTimeout(() => {
      state.isResetSortParams = false
    }, 0)
  },
  SET_ITEM_BY_ID(state, item) {
    state.item = item
  },
  SET_PAGINATION(state, data) {
    state.dataPagination = {
      pageNumber: data.number + 1,
      totalPages: data.totalPages,
    }
  },
  SET_PARAMS(state, params) {
    state.params = {
      ...state.params,
      ...params,
    }
  },
  DELETE_PARAM(state, param) {
    delete state.params[param.name]
  },
  SET_PAGE_NUMBER(state, {page}) {
    state.params = {
      ...state.params,
      page: page - 1,
    }
  },
  SET_SORT_PARAMS(state, {sortNameParam, sortSetParam}) {
    const sortStr = sortNameParam && sortSetParam ? `${sortNameParam},${sortSetParam}` : DEFAULT_PARAMS.sort
    state.params = {
      ...state.params,
      sort: sortStr,
    }
  },
  SET_CLIENTS(state, items) {
    state.userIdItems = convertObjToArray(items)
  },
  SET_USER_NAMES(state, {items, withId = true}) {
    state.userNameItems = convertObjToArray(items, false, withId)
  },
  SET_LOADING(state, {type, loading}) {
    switch (type) {
      case TYPE_LOADING_USER:
        state.loading = loading
        break
      case TYPE_LOADING_USER_ACTION:
        state.actionLoading = loading
        break
    }
  },
  RESET_PARAMS(state) {
    state.params = {...DEFAULT_PARAMS}
    state.isShowFilterBar = false
    state.selectedAppFilter = {}
    state.selectedTableFilters = []
    state.isResetSortParams = true
  },
  SET_APP_FILTER(state, {data = {}, isApi = false}) {
    state.isShowFilterBar = true

    if (isApi) {
      state.selectedAppFilter = data
    } else {
      const {value, placeholder, filterType} = data
      state.selectedAppFilter = {
        name: value,
        value: '',
        placeholder: placeholder ?? value,
        type: filterType,
      }
    }
  },
  SET_TABLE_FILTERS(state, data = undefined) {
    const {name} = state.selectedAppFilter
    const filtersToUpdate = data ?? state.selectedTableFilters

    if (!isDuplicateFilter(state.selectedTableFilters, name)) {
      addFilter(state, filtersToUpdate, data)
    } else {
      updateFilter(filtersToUpdate, state.selectedAppFilter)
    }

    const params = createParams(state.selectedTableFilters)

    // Сохраняем параметры и сбрасываем номер страницы
    this.commit('client/SET_PARAMS', params)
    this.commit('client/SET_PAGE_NUMBER', {page: 1})
  },
  DELETE_TABLE_FILTERS(state, data) {
    state.selectedTableFilters = state.selectedTableFilters.filter((a) => a.name !== data.name)
    this.commit('client/DELETE_PARAM', data)
    if (state.selectedTableFilters.length === 0) state.isShowFilterBar = false
  },
}

const actions = {
  async GET_ITEMS({commit, dispatch}, params) {
    commit('SET_LOADING', {type: TYPE_LOADING_USER, loading: true})
    commit('SET_ITEM_BY_ID', null)
    await getItems(commit, dispatch, TYPE_LOADING_USER, 'user/client/all', params)
  },
  async GET_ITEM_BY_ID({commit, dispatch}, id) {
    await getItemById(commit, dispatch, TYPE_LOADING_USER, `user/client/view/${id}`)
  },
  async EDIT_USER({commit, dispatch}, formData) {
    return await editItem(
      commit,
      dispatch,
      TYPE_LOADING_USER_ACTION,
      `user/client/update/${formData.id}`,
      formData,
      'toast.successUserEdit',
    )
  },
  async ARCHIVED_USER({commit, dispatch}, id) {
    return await deleteItem(
      commit,
      dispatch,
      TYPE_LOADING_USER_ACTION,
      `user/archive/${id}`,
      'toast.successArchive',
    )
  },
  async GET_CLIENTS({commit, dispatch}) {
    commit('SET_LOADING', {type: TYPE_LOADING_USER, loading: true})
    try {
      const res = await axios.get('dictionary/clients/read-all')
      if (res.status === 200) {
        commit('SET_CLIENTS', res.data)
      }
    } catch (err) {
      await customErrors(err, dispatch)
      commit('SET_CLIENTS', [])
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING_USER, loading: false})
    }
  },
  async GET_USER_NAMES({commit, dispatch}, withId = true) {
    commit('SET_LOADING', {type: TYPE_LOADING_USER, loading: true})
    try {
      const res = await axios.get('dictionary/filter/clients/read-all')
      if (res.status === 200) {
        commit('SET_USER_NAMES', {items: res.data, withId})
      }
    } catch (err) {
      await customErrors(err, dispatch)
      commit('SET_USER_NAMES', [])
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING_USER, loading: false})
    }
  },
  async GET_CURRENCY({commit, dispatch}, id) {
    try {
      const res = await axios.get(`dictionary/clients/${id}/currency`)
      if (res.status === 200) {
        const {data} = res
        return currencySymbols[data]
      }
    } catch (err) {
      await customErrors(err, dispatch)
      return null
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
